import {graphql} from 'gatsby';

import Documents from '../../screens/documents';
export default Documents;

export const query = graphql`
  query {
    allStrapiDocuments {
      edges {
        node {
          id
          title
          when
          time
          responsible
          file {
            publicURL
          }
        }
      }
    }
  }
`;
