import React from 'react';
import styled from 'styled-components';
import {compose} from 'recompose';
import {Link} from 'gatsby';
import Layout from '@p14/layouts';
import * as Components from '@p14/components';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/documents';
import docs from '@p14/texts/documents.json';

export const List = styled.section`
  margin: 50px 0 100px;
`;

export const Article = styled.article`
  margin: 0 0 22px;
`;

export const Date = styled.time`
  display: block;
  font-family: Geometria;
  font-size: 12px;
  color: #9b9b9b;
  margin-bottom: 2px;
`;

export const Title = styled.a`
  font-family: Geometria;
  font-size: 14px;
  color: #000;
  margin: 0;
  font-weight: normal;
  text-decoration: none;
  color: ${config.colors.main};
  transition: color 0.3s ease 0s;
  will-change: color;

  &:hover {
    color: ${config.colors.dark};
  }
`;

export const Subtitle = styled.span`
  display: block;
  font-family: Geometria;
  font-size: 12px;
  color: #9b9b9b;
  margin-top: 4px;
`;

export const Document = ({when, time, title, responsible, file}) => (
  <Article>
    <Title target="_blank" href={file.publicURL}>{title}</Title>
    <Subtitle>{`дата размещения ${when}`}</Subtitle>
    <Subtitle>{`время размещения ${time}`}</Subtitle>
    <Subtitle>{`ответственное лицо ${responsible}`}</Subtitle>
  </Article>
);

export const Button = styled(Link)`
  text-decoration: none;
  width: 199px;
  height: 56px;
  margin: 0 auto 80px;
  display: block;
  text-align: center;
  line-height: 56px;
  text-align: center;
  color: ${config.colors.light};
  font-size: 14px;
  background-color: #b48b72;
  transition: background-color 0.3s ease 0s;
  will-change: background-color;

  &:hover {
    background-color: #9b9b9b;
  }
`;

export const Footer = styled.p`
  margin: 0 auto 30px;
  max-width: 784px;
  font-family: Geometria;
  font-size: 12px;
  color: #000;
  line-height: 14px;
  text-align: center;
  color: ${config.colors.text};
`;

export const Documents = ({tr, data, ...props}) => (
  <Layout {...props}>
    <Components.Container>
      <Components.H1>{tr('title')}</Components.H1>
      <List>{data.allStrapiDocuments.edges.map(({node}, index) => <Document {...node} key={node.id}/>)}</List>
      <Button to="/documents/logs">Журнал изменений</Button>
      <Footer dangerouslySetInnerHTML={{__html: tr('footer')}}/>
    </Components.Container>
  </Layout>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(Documents);
